import { useState } from "react"
import { Typography } from "../../components"
import Button from "../../components/Button"
import { RightMark } from "../../images"
import { post_download_brochure_form } from '../../actions/send_email'
import { post_new_user } from '../../actions/post_data'
import { downloadBroucher } from '../../actions/common'

const DownloadForm = () => {
    const [recEmail, setRecEmail]= useState('')
    const [isFormSent, setisFormSent] = useState(false)
    const [isFormSuccess, setisFormSuccess] = useState(false)
    // const handleInputChange = (e:any) => {
    //     setRecEmail(e.target.value)
    // }
    // const handleSubmit = async (e: any) => {    
    //     e.preventDefault()
    //     await post_download_brochure_form(recEmail)
    //     const res = await post_new_user(recEmail)
    //     // const res=true
    //     if(res) { 
    //       setRecEmail('') 
    //       setisFormSent(true)
    //       setisFormSuccess(true)
    //       await downloadBroucher()
    //       setTimeout(() => {
    //           setisFormSent(false)
    //           setisFormSuccess(false)
    //       }, 2000)
    //     }else { 
    //     setRecEmail('') 
    //     setisFormSent(true)
    //     setTimeout(() => {
    //         setisFormSent(false)
    //     }, 2000)
    //     }
    // }
    const handleSubmit = async (e: any) => {    
      e.preventDefault()
      await downloadBroucher()
  }
    return (<form 
        onSubmit={handleSubmit}
      >
          <Button
            text={'Download'}
            className={'max-sm:mt-8'}
            size={'lg'}
            background={'fill'}
            isSubmitButton={true}
            isButtonDisable={false}
          >
            <Typography size={'md'} type={'regular'}>
              DOWNLOAD
            </Typography>
          </Button>
      </form>)
}

{/* <form
        className={`flex ${isFormSent ? 'justify-center' : ' max-sm:flex-col justify-between items-center md:border md:border-gradient'} rounded-full p-1 lg:w-1/2 w-full`} 
        onSubmit={handleSubmit}
      >
        {!isFormSent && <>
        {/* <p className="md:hidden text-sm text-[#6b7280] pb-4 italic">Enter your email address to download brochure</p> */}
        {/* max-sm:placeholder-opacity-0 */}
      //   <input
      //     type="email"
      //     className="md:border-none bg-transparent lg:w-[80%] max-sm:w-[94%] md:pl-8 outline-none italic max-sm:border max-sm:border-white rounded-full text-md max-sm:text-sm placeholder-[#6b7280] focus:ring-offset-0 focus:outline-none focus:border-current focus:ring-0"
      //     placeholder="Enter your email address to download brochure" value={recEmail}
      //     onChange={handleInputChange}
      //     required
      //     pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
      //   />
      //     <Button
      //       text={'Download'}
      //       className={'max-sm:mt-8'}
      //       size={'lg'}
      //       background={'fill'}
      //       isSubmitButton={true}
      //       isButtonDisable={false}
      //     >
      //       <Typography size={'md'} type={'regular'}>
      //         DOWNLOAD
      //       </Typography>
      //     </Button></>}
      //     {isFormSent && 
      //   <>
      //     <Typography
      //       type={'regular'}
      //       size={'lg'}
      //       className="italic m-3 pr-2"
      //     >
      //       {isFormSuccess ? 'Download Complete' : 'Please retry after sometime'}
      //     </Typography>
      //     {isFormSuccess && <img
      //       src={RightMark}
      //       alt="right-mark"
      //       className="md:w-[50px] w-[40px] h-auto"
      //       loading="lazy"
      //     />}
      //   </>
      // }
      // </form> */}

export default DownloadForm